





























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Watch } from 'vue-property-decorator'
import { Debounce } from 'lodash-decorators'

@Component({})
export default class Edit extends Vue {
  private data = {
    id: 0,
    daysAhead: 0,
    timeOfDay: '',
    transport: '',
    type: '',
    body: '',
    subject: '',
  }

  private statuses = []
  private tab = 'preview'
  private preview = ''

  public created(): void {
    this.statuses = [
      { id: 'Enabled', name: 'Enabled' },
      { id: 'Disabled', name: 'Disabled' },
    ]
  }

  private resetData() {
    this.data = {
      id: 0,
      daysAhead: 0,
      timeOfDay: '',
      transport: this.$route.params?.transport || '',
      type: this.$route.params?.type || '',
      body: '',
      subject: '',
    }
  }

  public mounted(): void {
    if (this.isNew) {
      this.resetData()
    } else {
      this.$axios
        .get(this.url)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  private save() {
    if (this.isNew) {
      this.$axios
        .post(this.url, this.data)
        .then((response) => {
          this.data = response.data.data
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    } else {
      this.$axios
        .put(this.url, this.data)
        .then((response) => {
          this.data = response.data.data
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  private cancel() {
    this.$router.back()
  }

  private get isNew() {
    return !(this.$route.params.id && parseInt(this.$route.params.id) !== 0)
  }

  private get url() {
    const url = '/v4/site/calendars/' + this.$route.params.calendarId + '/reminders'
    return this.isNew ? url : url + '/' + this.$route.params.id
  }

  private get isEmail() {
    return this.data?.transport === 'Email'
  }

  @Watch('data.body')
  @Debounce(500)
  private onBodyChange() {
    let id = this.data.id || '0'
    this.$axios
      .post('/v4/site/calendars/' + this.$route.params.calendarId + '/reminders/' + id + '/preview', {
        body: this.data.body,
      })
      .then((response) => {
        this.preview = response.data.data.text || ''
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load preview')
      })
  }
}
